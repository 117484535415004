// extracted by mini-css-extract-plugin
export var content__footer = "Footer-module--content__footer--91a66";
export var content__links = "Footer-module--content__links--11b2f";
export var content__links__category = "Footer-module--content__links__category--fa6c8";
export var copyrights = "Footer-module--copyrights--8c2f4";
export var desktop = "Footer-module--desktop--d96c7";
export var footer__wrapper = "Footer-module--footer__wrapper--3b191";
export var link__disabled = "Footer-module--link__disabled--b3261";
export var link_coming_soon = "Footer-module--link_coming_soon--20276";
export var logo = "Footer-module--logo--369c4";
export var mobile = "Footer-module--mobile--0a707";
export var mobile__footer = "Footer-module--mobile__footer--d22ec";
export var newsletter = "Footer-module--newsletter--ed599";
export var newsletter__error__message = "Footer-module--newsletter__error__message--3e81b";
export var newsletter__form = "Footer-module--newsletter__form--97de9";
export var newsletter__form__error = "Footer-module--newsletter__form__error--a39cf";
export var newsletter__success__msg = "Footer-module--newsletter__success__msg--ea150";
export var newsletter__title = "Footer-module--newsletter__title--788ab";
export var sandbox__content__links = "Footer-module--sandbox__content__links--88edc";
export var social_media_section = "Footer-module--social_media_section--d9b56";
export var wrapper = "Footer-module--wrapper--e0e73";