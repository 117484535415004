import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";

// Assets
import Logo from "../../images/icons/logo-default.svg";

// Utils
import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import * as styles from "../../styles/components/Footer.module.css";

const DesktopFooter = () => {
  const { t } = useI18next();
  const footerLinks = [
    {
      title: t("footer.categories.5.title"),
      links: [
        {
          name: t("footer.categories.5.links.1"),
          url: "https://www.linkedin.com/company/zealapps/",
        },
        {
          name: t("footer.categories.5.links.2"),
          url: "https://www.instagram.com/zeal.apps/",
        },
        {
          name: t("footer.categories.5.links.3"),
          url: "https://www.facebook.com/get.zeal",
        },
      ],
    },
  ];
  return (
    <div className={styles.content__footer}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={styles.content__links}
      >
        {footerLinks.map((link) => (
          <motion.div
            variants={revealVariant}
            key={link.title}
            className={styles.sandbox__content__links}
          >
            <h3>{link.title}</h3>
            <ul>
              {link.links.map((item) => (
                <li key={item.url}>
                  {item.url ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link to={item.link} disabled={!item.link}>
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

const SandboxFooter = () => {
  const { t } = useI18next();
  return (
    <>
      <footer className={styles.wrapper}>
        <div className="sectionContainer">
          <div className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <DesktopFooter />
          <motion.hr
            initial={{ width: 0 }}
            whileInView={{
              width: "100%",
              transition: { delay: 0.5, duration: 1 },
            }}
            viewport={{ once: true }}
          />
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 1, duration: 0.5 },
            }}
            viewport={{ once: true }}
            variants={revealVariant}
            className={`${styles.copyrights} !text-start`}
          >
            {t("footer.copyright", { date: new Date().getFullYear() })}
          </motion.p>
        </div>
      </footer>
    </>
  );
};

export default SandboxFooter;
